.error-page {
  background: url('/static/images/error-page-bg-mb.png') no-repeat center top;
  background-size: cover;

  @include media-breakpoint-up(md) {
    background: url('/static/images/error-page-bg-dt.png') no-repeat center top;
    background-size: cover;
  }

  &__inner {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;

    @include media-breakpoint-up(md) {
      min-height: 867px;
    }
  }

  &__number {
    font-family: var(--font-roboto), sans-serif;
    font-weight: 900;
    font-size: 160px;
    line-height: 187px;
    letter-spacing: 20px;
    color: #FFFFFF;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 400px;
      line-height: 400px;
      letter-spacing: 50px;
    }
  }

  &__msg {
    font-family: var(--font-roboto), sans-serif;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;

    @include media-breakpoint-up(md) {
      font-size: 40px;
      line-height: 47px;
      letter-spacing: normal;
    }
  }

  &__back-btn {
    width: 248px;
    font-size: 20px;
    line-height: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 50px;
  }
}

@import "share";

:root {
  --mainColor: #F9A826;
  --textColor: #fff;
  --mdc-theme-primary: #f9a826;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, 'Helvetica Neue', sans-serif;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

@media print {
  p {
    orphans: 3;
  }
}

body {
  font-family: var(--font-roboto), sans-serif;
  font-weight: 400;
  min-height: 100%;
}
nav {
  font-family: var(--font-roboto), sans-serif;
  font-weight: 500;
}
.no-border {
  border: none !important;
}
.no-background {
  background-color: transparent !important;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.container-fluid {
  padding: 0 30px;
  margin: 0 auto;
}
.bg-red {
  background-color: var(--mainColor);
  color: var(--textColor);
  font-size: 16px;
  border-radius: 0;
}

.btn-danger,
.btn-danger:active,
.btn-danger:hover,
.btn-danger:visited,
.btn-danger:focus,
.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger:hover,
.btn-outline-danger:visited,
.btn-outline-danger:focus {
  border-color: var(--mainColor);
}

.btn-danger:hover,
.btn-outline-danger:hover {
  background-color: var(--mainColor);
  opacity: 0.9;
}


.fixed-header {
  background-color: #550015;
  animation: fade 2s linear;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.pc {
  @include media-breakpoint-down(lg) {
    display: none !important;
  }
}
.mobile {
  display: none;

  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-color: #fdb0b0;
  border-bottom: 2px solid #b00020 !important;
}

.nowrap {
  white-space: nowrap;
  word-break: keep-all;
}

.mdc-text-field {
  width: 100%;

  &:not(.mdc-text-field--disabled) {
    background-color: white;
    + .mdc-text-field-helper-line .mdc-text-field-helper-text {
      color: #b00020 !important;
    }
  }

  &--focused {
    background-color: white !important;

    &:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        color: #202020 !important;
      }
    }
  }

  &--invalid {
    background: #fdb0b0 !important;
  }
}

.form-invalid {
  .mdc-text-field--invalid {
    background: unset!important;
    color: rgba(0, 0, 0, 0.6)!important;
    .mdc-text-field__icon {
      display: none!important;
    }
    .mdc-line-ripple {
      background-color: var(--mdc-theme-primary, #6200ee)!important;
    }
    .mdc-text-field__input {
      border-bottom-color: rgba(0, 0, 0, 0.12)!important;
    }
    .mdc-floating-label {
      color: rgba(0, 0, 0, 0.6)!important;
    }
  }
  // .mdc-text-field-helper-line {
  //   opacity: 0;
  // }
  // .mdc-text-field-helper-text--persistent {
  //   opacity: 0;
  // }
}

.dolce-tabs {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.15);

  .mdc-tab-indicator > .mdc-tab-indicator__content--underline {
    background-color: #f70d28;
  }
  .mdc-tab .mdc-tab__text-label,
  .mdc-tab--active .mdc-tab__text-label {
    color: #212121;
    font-family: var(--font-roboto), sans-serif;
    font-weight: bold;
    font-size: 16px;
    opacity: 1;
  }
  .mdc-tab-scroller {
    max-width: 940px;
    margin: 0 auto;
  }
  .mdc-tab-indicator {
    width: auto;
    left: 24px;
    right: 24px;
  }
  .mdc-tab {
    &:focus {
      outline: none;
    }
  }
}

/*=========== SPINNER =============*/
#nprogress {
  .spinner {
    top: 50% !important;
    right: 50% !important;
  }
  .spinner-icon {
    width: 20px !important;
    height: 20px !important;
  }
}
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 10;
}

/* Safari */
@-webkit-keyframes loader-simple {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-simple {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lazy-background {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=') no-repeat #000 !important;
  // background: none #000!important;
}


@keyframes fadeIn {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

body {
  #nprogress .spinner-icon {
    width: unquote('calc(30px + 0.3rem)')!important;
    height: unquote('calc(30px + 0.3rem)')!important;
    border-width: 0.3rem!important;
    border-left-color: transparent!important;
    animation-duration: 1.1s!important;
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@keyframes slide-bottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

.slide-bottom {
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}

.slide-top {
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@keyframes slide-fade-in-top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-fade-in-top {
  animation: slide-fade-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@keyframes slide-fade-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-30px);
    opacity: 0;
  }
}

.slide-fade-out-top {
  animation: slide-fade-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}


/*========SWIPER SLIDER==========*/
.swiper-button-prev,
.swiper-button-next {
  outline: none;
}
.swiper-pagination-bullet {
  bottom: 50px;
  border: 2px solid #fff;
  opacity: 1;
  background: transparent;
  padding: 10px;
  margin: 0 10px !important;
  height: 20px;
  width: 20px;
}

.swiper-pagination-bullet-active {
  background-color: #fff;
}

.term-policy {
  text-align: left;
  ol {
    list-style-type: none;
    padding: 0;
  }
  h2,
  h4 {
    font-size: 14px;
  }
}
.grecaptcha-badge {
  z-index: 2;
}